<template>
  <div class="login-container">
    <!-- 左图 -->
    <img class="left_bg" src="../../assets/login/login_bg.png" alt="" />
    <!-- <img class="logo_tip" src="../../assets/login/logo_blue.png" alt="" /> -->
    <div class="index_com">
      <div class="content">
        <!-- 全国热线：400-888-9792<br />logo 全国热线：400-888-9792 沪公网安备
        31011202012092号<br />蓝亚（深圳）信息技术有限公司 -->
        客户服务热线：18026933971 <br />
        蓝亚（深圳）信息技术有限公司<br />
        <a target="_blank" href="https://beian.miit.gov.cn"
          >网站备案号：粤ICP备17165058号-8</a
        >
      </div>
    </div>
    <div class="login-box">
      <el-form
        class="login-form"
        ref="loginFromRef"
        :model="loginForm"
        :rules="loginRules"
      >
        <div class="title-container">
          <h3 class="title">
            <template v-if="ScanTitle"> 验证码登录 </template>
            <template v-else> 扫码登录 </template>
          </h3>
          <!-- <lang-select class="lang-select" effect="light"></lang-select> -->
        </div>
        <div class="toCode" v-if="ScanTitle">
          <el-form-item prop="userName" style="width: 90%">
            <el-input
              prop="userName"
              placeholder="请输入手机号码"
              name="userName"
              @focus="GetUserName()"
              @blur="leaveUserName()"
              v-model="loginForm.userName"
            />
          </el-form-item>

          <div class="line-code">
            <div class="code">
              <el-form-item prop="userPwd" style="width: 90%">
                <el-input
                  placeholder="请输入验证码"
                  v-model="loginForm.userPwd"
                />
              </el-form-item>
              <div
                class="getCode1"
                v-if="codeDisabled"
                style="
                  position: absolute;
                  right: 21px;
                  top: 9px;
                  color: #2489f2;
                  border-left: 1px solid #e5e7ea;
                "
              >
                <div
                  @click="getCode()"
                  style="
                    width: 90px;
                    margin-left: 10px;
                    cursor: pointer;
                    height: 26px;
                    line-height: 26px;
                  "
                >
                  发送验证码
                </div>
              </div>
              <div
                class="getCode1"
                v-else
                style="
                  position: absolute;
                  right: 21px;
                  top: 10px;
                  color: #c1c6cd;
                  border-left: 1px solid #e5e7ea;
                "
              >
                <div
                  style="
                    width: 90px;
                    margin-left: 10px;
                    cursor: not-allowed;
                    text-align: center;
                  "
                >
                  {{ codeMeg }}
                </div>
              </div>
            </div>
          </div>

          <el-button
            type="primary"
            style="
              width: 90%;
              margin-bottom: 30px;
              margin-left: 5%;
              font-size: 16px;
            "
            :loading="loading"
            @click="handleLogin"
            >{{ $t('msg.login.loginBtn') }}</el-button
          >
        </div>
        <div class="login-form erweima" v-else>
          <qrcode-vue
            :value="qrcode"
            :size="240"
            level="H"
            style="text-align: center"
          />

          <!-- <div id="qrcodeImg"></div> -->
          <div class="content" style="text-align: center">
            打开“小蓝智农”小程序扫码登录
          </div>
        </div>
      </el-form>
      <div class="bottom_title" @click="Scan()">
        <template v-if="ScanTitle"> 扫码登录 </template>
        <template v-else> 验证码登录</template>
      </div>
      <!-- <img src="../../assets/login/login-background.f9f49138.jpg" alt="" /> -->
      <div class="login_rightTop_language_wrap">
        <div
          @mouseenter="entersLang"
          @mouseleave="leaverLang"
          @click="selectLang"
          class="language_select_box"
          :class="langFlag ? 'active' : 'noactive'"
        >
          <span v-if="langFlag">
            <svg-icon icon="IT" />
          </span>
          <span v-else><svg-icon icon="BlueIT" /></span>
          <span class="language_title">{{ selectValue }}</span>
          <span
            class="language_downArror"
            :class="clickFlag ? 'rotate' : 'norotate'"
          >
            <span :class="langFlag ? 'active' : 'noactive'">
              <svg-icon icon="downArror" />
            </span>
          </span>
        </div>
        <ul class="langList_box" :class="clickFlag ? 'clickNow' : 'noClick'">
          <div class="list_wrap">
            <li class="langListItem">
              <span class="langListItem_detail" @click="selectCh"
                >简体中文</span
              >
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
// 导入组件之后无需注册可直接使用
// getCurrentInstance 上下文对象

import { ElMessage } from 'element-plus';
import { ref, getCurrentInstance, onMounted, defineProps, computed } from 'vue';
import { validatePassword } from './rules';
import { useI18n } from 'vue-i18n';
import QRCode from 'qrcodejs2'; // 引入qrcode
import QrcodeVue from 'qrcode.vue';

// 导入组件
import { useStore } from 'vuex';
// import LangSelect from "@/components/LangSelect";
import { useRouter } from 'vue-router';
// 获取上下文proxy
const { $api } = getCurrentInstance().appContext.config.globalProperties;

const qrcode = ref();
const num = ref(0);
// 挂载页面
onMounted(() => {
  $api.getQrCode().then((res) => {
    qrcode.value = res.msg;
    num.value = 1;
    ScanStatus(qrcode.value);
  });
  Scan();
});

const langFlag = ref(true); // 显示选择语言弹窗
console.log('langFlag', langFlag.value);
// 鼠标进入
const entersLang = () => {
  langFlag.value = !langFlag.value;
};
// 鼠标移出
const leaverLang = () => {
  langFlag.value = !langFlag.value;
};
// 鼠标点击
const clickFlag = ref(false);
const selectLang = () => {
  clickFlag.value = !clickFlag.value;
};
// 选择语言
const selectValue = ref('简体中文');
const selectCh = () => {
  clickFlag.value = !clickFlag.value;
  selectValue.value = '简体中文';
};

// 去扫码登录
const ScanTitle = ref(true);
const Scan = () => {
  ScanTitle.value = !ScanTitle.value;
};

// 轮询
const ScanStatus = (qrCode) => {
  const outerTime = setInterval(function () {
    const data = {
      qrCode
    };
    console.log('qrcode', data);
    $api.getQrCodeStatus(data).then((res) => {
      const { data, code } = res;
      console.log('轮询结果', data);
      if (data.status == 1) {
        ElMessage.success({
          message: '登录成功',
          type: 'success',
          duration: 1000,
          onClose: () => {
            if (code === 200) {
              store.commit('user/setToken', data.token);
              store.commit('user/setUserInfo', data.user);
              // 登录后操作
              router.push('/');
              clearInterval(outerTime);
            }
          }
        });
      }
    });
  }, 3000);
};

// 数据源
const loginForm = ref({
  userName: '',
  userPwd: ''
});
// 验证规则
const i18n = useI18n();
// 验证规则
const loginRules = ref({
  userName: [
    {
      required: true,
      trigger: 'blur',
      message: i18n.t('msg.login.usernameRule')
    }
  ],
  userPwd: [
    {
      required: true,
      trigger: 'blur',
      message: '验证码不能为空'
    }
  ]
});
// 处理密码框文本显示状态
const passwordType = ref('password');
const onChange = () => {
  if (passwordType.value === 'password') {
    passwordType.value = 'text';
  } else {
    passwordType.value = 'password';
  }
};

// 输入框

/** 用户名焦点事件 */
const noInputun = ref(true);
/** 密码焦点事件 */
const noInputpw = ref(true);
/** 鼠标进入--输入用户名 */
const GetUserName = () => {
  noInputun.value = false;
};
/** 鼠标离开--用户名输入框 */
const leaveUserName = () => {
  noInputun.value = true;
};

// 登录动作处理
const loading = ref(false);
const loginFromRef = ref(null);
const store = useStore();
const router = useRouter();
const handleLogin = () => {
  loginFromRef.value.validate((valid) => {
    if (!valid) return;
    loading.value = true;
    const data = {
      phone: loginForm.value.userName,
      code: loginForm.value.userPwd
    };
    $api.login(data).then((res) => {
      console.log('rere', res);
      if (res.code == 200) {
        ElMessage.success({
          message: '登录成功',
          type: 'success',
          duration: 500
        });
        loading.value = false;
        store.commit('user/setToken', res.data.token);
        store.commit('user/setUserInfo', res.data);
        // 登录后操作
        router.push('/');
      } else {
        loading.value = false;
        ElMessage.error(res.msg);
      }
    });
  });
};

// 根据手机号获得验证码
const timer = ref();
const countdown = ref(60);
const codeMeg = ref('获取短信验证码');
const codeDisabled = ref(true);
const getCode = () => {
  console.log('loginFromRef.value', loginFromRef.value);

  if (
    !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
      loginForm.value.userName
    )
  ) {
    ElMessage.error('手机号码有误，请重填');
  } else {
    const data = {
      phone: loginForm.value.userName
    };
    $api.getCode(data).then((res) => {
      if (res.code == 200) {
        ElMessage.success({
          message: '发送成功',
          type: 'success',
          duration: 500
        });

        if (!timer.value) {
          timer.value = setInterval(() => {
            if (countdown.value > 0 && countdown.value <= 60) {
              countdown.value--;
              if (countdown.value !== 0) {
                codeDisabled.value = false;
                codeMeg.value = countdown.value;
              } else {
                clearInterval(timer.value);
                codeMeg.value = '获取验证码';
                countdown.value = 60;
                timer.value = null;
                codeDisabled.value = true;
              }
            }
          }, 1000);
        }
      } else {
        ElMessage.error(res.msg);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #737478;
$cursor: #fff;

li {
  list-style: none;
}
.active {
  color: #4c596e !important;
}
.noactive {
  color: #2589f2 !important;
}

.rotate {
  transition: all 0.3s ease;
  transform: rotate(180deg);
}
.norotate {
  transition: all 0.3s ease;
  transform: rotate(0deg);
}

.clickNow {
  display: block !important;
}
.noClick {
  display: none !important;
}

.code {
  // display: flex;
  position: relative;
  .getcode1 {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.login-container {
  min-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  .left_bg {
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .logo_tip {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 140px;
    height: 35px;
  }
  .index_com {
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    color: #7f8997;
    bottom: 20px;
    left: 50px;
    line-height: 20px;
    .content {
      color: #fff;
    }
  }
  .login-box {
    -webkit-box-flex: 1;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .bottom_title {
      position: absolute;
      bottom: 30px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #7f8997;
      cursor: pointer;
    }
    .login_rightTop_language_wrap {
      position: absolute;
      top: 20px;
      right: 20px;

      .language_select_box {
        cursor: pointer;
        width: 130px;
        height: 44px;
        line-height: 44px;
        color: #4c596e;
        text-align: center;
        font-size: 15px;
        .language_title {
          display: inline-block;
          width: 75px;
        }
        .language_downArror {
          display: inline-block;
          font-size: 12px;
          vertical-align: 0.15em;
          color: #7f8997;
        }
      }
      .langList_box {
        color: #4c596e;
        cursor: pointer;
        display: none;
        box-shadow: 0 0 2px 1px rgba(0, 19, 48, 0.05);
        .list_wrap {
          padding: 10px 0;
          .langListItem {
            height: 32px;
            line-height: 32px;
            background-color: #f7f8f8;
            .langListItem_detail {
              display: inline-block;
              margin-left: 18px;
              font-size: 14px;
              color: #001330;
            }
          }
        }
      }
    }
  }
  .erweima {
    text-align: center;
    .content {
      padding: 20px 0;
    }
  }
  .login-form {
    position: relative;
    width: 420px;
    height: 450px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 5px;
    :deep(.el-form-item) {
      height: 45px;
      border-radius: 5px;
      color: #737478;
      width: 90%;
      margin-left: 5%;
      margin-bottom: 25px;
      .svg-container {
        height: 35px;
        line-height: 35px;
      }
    }

    :deep(.el-input) {
      display: inline-block;
      height: 45px;
      font-size: 16px;
      .el-input__wrapper {
        width: 100%;
        height: 45px;
        // line-height: 40px;
      }
      .el-input__inner {
        // 设置光标颜色
        caret-color: black;
        height: 45px;
        line-height: 45px !important;
      }
    }
  }

  .tips {
    font-size: 16px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 0px 5px 0px 15px;
    color: $dark_gray;
    vertical-align: middle;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      // cursor: pointer;
      font-size: 24px;
      color: #001330;
      font-weight: 500;
      margin: 20px 0px 48px auto;
      text-align: center;
    }

    :deep(.lang-select) {
      position: absolute;
      top: 4px;
      right: 0;
      background-color: white;
      font-size: 22px;
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
